import { HttpClient } from '@angular/common/http';
import { DoCheck, Injectable } from '@angular/core';
import { IonRouterOutlet, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    nanoStoreInUse: boolean;
    loading: HTMLIonLoadingElement;
    constructor(
      protected http: HttpClient,
      private toastController: ToastController,
      private modalController: ModalController,
      private loadingController: LoadingController
    ) {}

    public changeAppTheme(user){
      console.log("change color", user)
      document.documentElement.style
      .setProperty('--header-color', user.headerColor);

      document.documentElement.style
      .setProperty('--header-text-color', user.headerTextColor);

      document.documentElement.style
      .setProperty('--ion-color-primary', user.color);

      document.documentElement.style
      .setProperty('--ion-color-secondary', user.secondaryColor);
    }

    public getPicture(id, type) {
      return this.http.get<any[]>(`${environment.apiUrl}picture?id=${id}&type=${type}`);
    }

    public async openToast(message: string, color: string) {
      let toast = await this.toastController.create({
        message: message,
        duration: 3000,
        color: color,
        position: 'top',
        keyboardClose: true,
        mode: "ios",
        buttons: [{
          side: 'end',
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }
        ]
      });
      await toast.present();
      return toast;
    }

    async openToastError(message: string) {
      return await this.openToast(message, "danger");
    }
    async openToastSuccess(message: string) {
      return await this.openToast(message, "success");
    }

    async openModal(user, component, routerOutlet:IonRouterOutlet, callBack) {

      let modal = await this.modalController.create({
        component: component,
        backdropDismiss: false,
        swipeToClose: true,
        presentingElement: routerOutlet.nativeEl,
        componentProps: {
          currentUser: user
        }
      });
      await modal.present();
  
      await modal.onWillDismiss().then((res) => {
        if (res.data && res.data.created) {
          callBack(true);
        } else {
          callBack(false);
        }
      })
  
      return modal;
    }

    async presentLoading(message = null) {
      this.loading = await this.loadingController.create({
        message: message ? message : 'Veuillez patienter...',
        backdropDismiss: true
      });
      await this.loading.present();
    }

    clearLoading() {
      if(this.loading) {
        this.loading.dismiss();
      }
    }
}