export const environment = {
  production: true,
  apiUrl: "https://dev.livepick.ai/",
  AzureRedirectUri: "https://dev.app.livepick.ai",
  facebook_auth_client_id: "357359291637009",
  google_auth_client_id: "994363955392-i6gsaspado7s3ca2kmis1cpi25p5e34t.apps.googleusercontent.com",
  generatePhotoUrl: "https://eu.ui-avatars.com/api/?background=DFD22B&color=fff&name=",
  stripe_private_key: "pk_test_51JvkU8AhDXjY440vxyn2qmhVhkXfDE3dodKL2QlkVc3yGMVfdB7BsMnBSlaQQJYuzGAcDozacSd3tHRrimkAUeMq00YpZ6HgSS",
  socketUrl : "wss://dev.livepick.ai:5678/",
  hammel_bank_imprint :50000
};
