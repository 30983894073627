import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor{
    constructor(private authService: AuthService) {}
 
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authService.currentUserValue;
        if(currentUser && currentUser.token){
            request = request.clone({ headers: request.headers.set('authorization', currentUser.token ) });
            return next.handle(request);
        }else{

            return next.handle(request);
        }

        
    }
}
