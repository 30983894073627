import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'login',
    redirectTo: 'page',
    pathMatch: 'full'
  },
  {
    path: 'page',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'not-found',
    loadChildren: () => import('./page/not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./page/categories/categories.module').then( m => m.CategoriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'article/:ean',
    loadChildren: () => import('./page/single-article/single-article.module').then( m => m.SingleArticlePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cards',
    loadChildren: () => import('./page/cards/cards.module').then( m => m.CardsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./tabs/search/search.module').then(m => m.SearchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'connect',
    loadChildren: () => import('./page/auth/connect/connect.module').then( m => m.ConnectPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./page/auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'qrcode-scanner',
    loadChildren: () => import('./page/qrcode-scanner/qrcode-scanner.module').then( m => m.QrcodeScannerPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test/test.module').then( m => m.TestPageModule)
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' },

  

  

  

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
