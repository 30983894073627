import { Component } from '@angular/core';

import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { HostListener } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { WebsocketService } from './services/websocket.service';
import * as $ from "jquery";
import { GlobalService } from './services/global.service';
import { UpdateService } from './services/update.service';
export { VERSION } from '../environments/version'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @HostListener('window:unload', ['$event'])
  @HostListener('window:load', ['$event'])
  unloadHandler(event) {
    // window.localStorage.clear();
  } 

  loadHandler(event) {
    this.initializeApp();
  }

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private authService: AuthService,
    private websocketService: WebsocketService,
    private globalService: GlobalService
  ) {
    if (this.authService.currentUserValue != null) {
      let user = this.authService.currentUserValue;
      this.websocketService.connection(user.id);
      this.globalService.changeAppTheme(user);

      document.documentElement.style.setProperty('--primary', user.color);
    }
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    // toggleDarkTheme(prefersDark.matches);

    // // Listen for changes to the prefers-color-scheme media query
    // prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

    // Add or remove the "dark" class based on if the media query matches
    function toggleDarkTheme(shouldAdd) {
      document.body.classList.toggle('dark', shouldAdd);
    }
  }


  initializeApp() {
    $(document).ready(function () {
      this.splashScreen.hide();
    })
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
    });
  }

  opensweetalert() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      timer: 10000
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )

      } else {
        // this.authService.logout();
      }
    });
  }


}
