import { Review } from '../review/review';


const ROLE_ADMIN = 1;
const ROLE_BACKOFFICE = 2;
const ROLE_ENTERPRISE = 3;
const ROLE_CUSTOMER = 4;
const ROLE_MAINTENANCE = 5;
const ROLE_REPLENISHMENT = 6;
const ROLE_RESCUE = 7;
const ROLE_INVENTORY = 8;

export class User {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    idStripe: string;
    roleId: number;
    role: string;
    token: string;
    balance: number;
    isLoggedIn?: boolean;
    photoUrl?: string;
    date_start?: string;
    password?: string;
    status?: string;
    reviews?: Review[];
    warning?: string;
    enterprise?: string;
    logo?: string;
    color?: string;
    secondaryColor?: string;
    headerColor?: string;
    headerTextColor?: string;
    typeName?: string;
    currency?: string;
    initials: string;
    attributes: UserAttributes | null;

    constructor(user) {
        this.id = user.id
        this.firstname = user.firstname
        this.lastname = user.lastname
        this.email = user.email
        this.idStripe = user.idStripe
        this.token = user.token
        this.balance = user.balance
        this.isLoggedIn = user.isLoggedIn;
        this.photoUrl = user.photoUrl;
        this.date_start = user.date_start;
        this.password = user.password;
        this.status = user.status;
        this.reviews = user.reviews;
        this.warning = user.warning;
        this.enterprise = user.enterprise;
        this.logo = user.logo;
        this.color = user.color;
        this.role = user.role;
        this.roleId = user.roleId;
        this.secondaryColor = user.secondaryColor;
        this.headerColor = user.headerColor;
        this.headerTextColor = user.headerTextColor;
        this.typeName = user.typeName;
        this.currency = user.currency;
        this.initials = (this.firstname.charAt(0) + this.lastname.charAt(0)).toUpperCase();
        this.attributes = user.attributes
    }

    isAdmin() {
        return this.roleId == ROLE_ADMIN;
    }

    isEmployee() {
        return this.roleId == ROLE_REPLENISHMENT || this.roleId == ROLE_MAINTENANCE || this.roleId == ROLE_INVENTORY;
    }

    isCustomer() {
        return this.roleId == ROLE_CUSTOMER;
    }
}


export interface UserAttributes {
    address: string,
    city: string,
    has_delayed_payment: boolean,
    organization: string,
    phone: string,
    postal_code: string,
    sap_code: string
    sap_siret: string,
    user: Array<{
        email: string
    }>;
}
